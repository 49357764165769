<template>
  <div class="home">

    <v-dialog
      v-model="dialog_advertencia"
      width="600"
	  persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          ¡Advertencia!
        </v-card-title>

        <v-card-text>
          <p class="mt-3 text-subtitle-1">No autorizamos ni respaldamos los servicios turísticos no autorizados dentro de nuestro sitio web.</p>
		  <p class="text-subtitle-1">Estimado visitante:</p>
		  <p class="text-subtitle-1">Queremos informarte que únicamente nos hacemos responsables de los servicios turísticos que están debidamente autorizados y registrados en nuestro sitio web. El uso de los servicios turísticos ajenos, es de responsabilidad directa del visitante.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_advertencia = false"
          >
            Entiendo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialog_resena"
      max-width="900"
    >
      <v-card class="pa-3">
	  <v-form 
		ref="form"
		v-model="valid"
		lazy-validation
	  >
        <v-card-title class="text-h5 pb-0">
          Nueva Reseña
        </v-card-title>
		<v-card-subtitle class="mt-1 fs-1 pb-0">
			Para nosotros y los visitantes es importante conocer la experiencia que tuviste visitando nuestro Santuario
		</v-card-subtitle>
		<p class="ms-6 text-subtitle-1 grey--text">
			<v-icon>mdi-calendar</v-icon>&nbsp;
			Fecha: 04 de Enero de 2023
		</p>
		<v-divider></v-divider>
        <v-card-text>
			<v-card outlined class="pa-1">
			<v-card-text>

				<v-row>
				<v-col cols="4">
						<v-img class="mx-auto" v-if="image_user!=''" width="190" :src="image_user"/>
						<v-img class="mx-auto" v-else width="190" src="@/assets/user2.png"/>
						<v-col cols="12" class="pt-3">
							<v-file-input
							id="file_imagen_principal"
							label="Cambiar Foto"
							prepend-icon="mdi-camera"
							@change="updateFile"
							outlined
							dense
							>
								<template v-slot:selection="{ text }">
									<v-chip
										small
										label
										color="primary"
									>
										{{ text }}
									</v-chip>
								</template>
							</v-file-input>
						</v-col>
				</v-col>
				<v-col cols="8">
						<v-col cols="12" class="py-0">
							<v-text-field 
							dense 
							:rules="camposRules" 
							outlined label="Autor" 
							v-model="resena.autor" />
						</v-col>
						<v-col cols="12" class="py-0">
							<v-text-field 
							dense 
							:rules="camposRules" 
							outlined label="Titulo" 
							v-model="resena.titulo" />
						</v-col>
						<v-col cols="12" class="py-0">
							<v-textarea
								label="Contenido"
								outlined
								counter
								v-model="resena.contenido"
								:rows="4"
								:rules="contenidoRules"
							></v-textarea>
						</v-col>
				</v-col>
				</v-row>
			</v-card-text>
		  <v-divider></v-divider>
		  <v-card-subtitle class="fs-1 text-center">Califique su experiencia de visita en el Santuario de Copacabana</v-card-subtitle>
		  <v-row>
		  	<v-rating
				v-model="resena.rating"
				background-color="orange lighten-3"
				color="orange"
				large
				class="mx-auto"
				:rules="ratingRules"
			></v-rating>
		  </v-row>
			</v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog_resena = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="validateResena"
          >
            Guardar
          </v-btn>
        </v-card-actions>
		</v-form>
      </v-card>
    </v-dialog>
	<div class="hero">
		<video autoplay loop muted plays-inline class="back-video">
			<source src="@/assets/video/video_principal.mp4" type="video/mp4">
		</video>
		<nav class="d-none d-sm-none d-md-flex navbar">
			<img src="@/assets/copa_logo.png" class="logo">
			<ul>
				<li>
					<router-link class="underline-none" to="/" v-slot="{ navigate }">
						<a @click="navigate">inicio</a>
					</router-link>
				</li>
				<li>
					<router-link class="underline-none" to="/HomeAtractivos" v-slot="{ navigate }">
						<a @click="navigate">
							atractivos turísticos
						</a>
					</router-link>
				</li>
				<li>
					<router-link class="underline-none" to="/SiteHospedajes" v-slot="{ navigate }">
						<a @click="navigate">
							hospedajes
						</a>
					</router-link>
				</li>
				<li>
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<a href="#">
								<span
								v-bind="attrs"
								v-on="on"
								>
								servicios
								<v-icon class="color-white">
									mdi-menu-down
								</v-icon>
								</span>
								
							</a>
						</template>
						<v-list>
							<router-link to="/HomeServices/1">
								<v-list-item>
								<v-list-item-title class="font-weight-medium cursor">
									TRANSPORTE
								</v-list-item-title>
								</v-list-item>
							</router-link>
							<router-link to="/HomeServices/2">
								<v-list-item>
									<v-list-item-title class="font-weight-medium cursor">
									RESTAURANTE
									</v-list-item-title>
								</v-list-item>
							</router-link>
							<router-link to="/HomeServices/3">
								<v-list-item>
									<v-list-item-title class="font-weight-medium cursor">
									AGENCIA DE VIAJE
									</v-list-item-title>
								</v-list-item>
							</router-link>
							<router-link to="/HomeGuides">
								<v-list-item>
									<v-list-item-title class="font-weight-medium cursor">
									GUÍAS DE TURISMO
									</v-list-item-title>
								</v-list-item>
							</router-link>
						</v-list>
					</v-menu>
				</li>
				<li>
					<router-link class="underline-none" to="/SiteNoticias" v-slot="{ navigate }">
						<a @click="navigate">
							noticias
						</a>
					</router-link>
				</li>
				
			</ul>
		</nav>
		<div class="content">
			<h1>Copacabana</h1>
			<h3>¡Te invitamos a descubrir la belleza de Copacabana!</h3>
			<a href="#div_ini"><v-icon left dark large>mdi-chevron-down</v-icon>	Explorar</a>
		</div>
	</div>
	<div id="div_ini">
		<v-container style="padding-top: 50px; padding-bottom: 0px;">
			<v-row align="center" justify="center">
				<v-col cols="12" md="6" sm="6" lg="5" xl="5">
					<div class="wow fadeInLeft">
						<div class="text-left">
							<span class="subtitle-box">Municipio turístico de Bolivia</span><br>
							<span class="title-box">Copacabana</span><br>
							<hr class="separador-hr">
						</div>
						<br>
						<p class="color-content">
							Copacabana se encuentra a orillas del Lago Titicaca, cuna de las más importantes civilizaciones andinas. En el Municipio existen 33 comunidades campesinas originarias con un total aproximado de 15000 habitantes, en su mayoria de origen Aymara y Quechua. El Lago Titicaca, considerado sagrado por sus habitantes, fue habitado por las culturas prehispánicas Chiripa, Wancarani, Tihuanaco, Inca, Aymara y otras, en distintos periodos.
						</p>
					</div>
				</v-col>
				<v-col cols="12" md="6" sm="6" lg="5" xl="5" class="pr-10 pl-10">
					<v-img width="600" class="wow fadeInRight img-sepia" src="../assets/copaini.png"></v-img>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div class="curved-div">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
			<path fill="#0099ff" fill-opacity="1" d="M0,192L80,208C160,224,320,256,480,240C640,224,800,160,960,144C1120,128,1280,160,1360,176L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
		</svg>
		<div style="background: #0099ff;">
			<v-row>
				<v-col cols="12">
					<span class="fs-5 font-weight-bold">¿Qué puedes hacer?</span>
					<p>
						<span class="fs-1-2">
							Planes para todos los gustos
						</span>
					</p>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<carousel-3d v-if="list_actividades.length > 0" autoplay :autoplayTimeout="3000" :perspective="0" :space="400" :display="list_actividades.length" :height="400" class="carousel center-align">
						<slide v-for="(act ,index) in list_actividades" :key="index" :index="index">
							<div @click="openLink('HomeAtractivo', act.id)" :id="'Carouseldiv'+index" class="carousel-item pointer">
								<p class="titulo">
									{{act.nombre}}
								</p>
								<hr class="linea-card">
								<p class="subtitulo">{{act.descripcion}}</p>
								<img :src="$Api_url_media+act.img_principal">
							</div>
						</slide>
					</carousel-3d>
				</v-col>
			</v-row>
		</div>           
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
			<path fill="#0099ff" fill-opacity="1" d="M0,0L48,10.7C96,21,192,43,288,90.7C384,139,480,213,576,234.7C672,256,768,224,864,186.7C960,149,1056,107,1152,117.3C1248,128,1344,192,1392,224L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
		</svg>  
	</div>
	<div id="div_atractivos">
		<v-container style="padding-top: 40px; padding-bottom: 40px;">
			<v-row>
				<v-col cols="12">
					<div class="text-left">
						<span class="subtitle-box">MARAVILLATE</span><br>
						<span class="fs-2">Visita nuestros Atractivos Turísticos</span>
						<hr class="separador-hr">
						<span class="fs-1">Maravillate con los paisajes hermosos del lago navegable más alto del mundo</span><br>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-row>
						<v-col cols="12" sm="4" md="3" v-for="atr in list_atractivos" :key="atr.id">
							<div class="wow fadeInLeft grid">
								<figure class="effect-sadie">
									<v-img aspect-ratio="1"
										class="grey lighten-2"
										:src="$Api_url_media+atr.img_principal"
									></v-img>
									<figcaption>
										<h2>{{atr.nombre}}
										<span></span>
										</h2>
										<p>{{atr.descripcion}}</p>
										<router-link class="underline-none" :to="'/HomeAtractivo/'+atr.id" v-slot="{ navigate }">
											<a @click="navigate">Ver Más</a>
										</router-link>
									</figcaption> 
								</figure>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="text-center">
						<router-link class="underline-none" to="/HomeAtractivos" v-slot="{ navigate }">
							<v-btn @click="navigate" rounded large color="blue" outlined><v-icon left>mdi-arrow-right</v-icon>ver todo</v-btn>
						</router-link>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div id="eventos" style="background: #F7F7F7;">
		<v-container style="padding-top: 60px;">
			<v-row>
				<v-col cols="12">
					<div class="text-left">
						<span class="subtitle-box">CULTURA</span><br>
						<span class="fs-2">Disfruta nuestros Eventos Programados</span>
						<hr class="separador-hr">
						<span class="fs-1">Sé parte de las festividades que combinan la fé religiosa con las tradiciones andinas</span><br>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-container style="padding-bottom: 60px;">
			<v-row>
				<v-col cols="12">
					<carousel-3d v-if="list_eventos.length > 0" :controls-visible="true" :controls-prev-html="'&#10092; '" :controls-next-html="'&#10093;'" 
               			:controls-width="30" :controls-height="60" :clickable="false" :disable3d="true" :height="400" :space="380" :border="0">
						<slide v-for="(evt ,index) in list_eventos" :key="index" :index="index">
							<div class="slick">
								<div>
									<a @click="openLink('HomeAtractivo', evt.id)">
										<div>
											<h4><small>{{evt.fecha | fecha_literal_mes}}</small>{{evt.nombre}}</h4>
										</div>
										<picture>
											<img :src="$Api_url_media+evt.img_principal" alt="Image">
										</picture>
									</a>
								</div>
							</div>
						</slide>
					</carousel-3d>
				</v-col>
			</v-row>
		</v-container>
	</div>
	<div id="servicios" class="banner-rotated">
		<v-img height="600" aspect-ratio="1.7" src="@/assets/img/a67.jpg">
			<v-row>
				<v-col cols="12" class="text-center">
					<h2 class="titulo-portada custom-title container-fluid white--text">Servicios</h2>
					<span class="text-h6 white--text">Variedad de servicios que se ajustan a tu presupuesto :</span>
				</v-col>
				<v-col cols="12">
					<v-container>
						<v-row>
							<v-col cols="1"></v-col>
							<v-col cols="10">
								<div style="background: rgba(247,247,247,0.9);" class="pa-4">
									<v-flex class="my-5">
										<v-row dense>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
												<router-link class="underline-none" :to="`/HomeServices/${2}`" v-slot="{ navigate }">
													<v-avatar @click="navigate" size="128" tile>
													<img
														src="@/assets/flaticon/menu.png"
														alt="como llegar"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Restaurantes</span><br>
													<span class="fs-0-9 grey--text">La mejor comida<br>
													del lugar</span>
												</router-link>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
												<router-link class="underline-none" :to="`/HomeServices/${1}`" v-slot="{ navigate }">
													<v-avatar @click="navigate" size="128" tile>
													<img
														src="@/assets/flaticon/taxi.png"
														alt="como llegar"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Transporte</span><br>
													<span class="fs-0-9 grey--text">Seguridad y Compromiso<br>
													Con nuestros pasajeros</span>
												</router-link>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
												<router-link class="underline-none" :to="`/HomeServices/${3}`" v-slot="{ navigate }">
													<v-avatar  @click="navigate" size="128" tile>
													<img
														src="@/assets/flaticon/travel-and-tourism.png"
														alt="conociendo Copacabana"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Agencias de Viaje</span><br>
													<span class="fs-0-9 grey--text">Te ayudamos a organizar el<br>
													viaje de tus sueños</span>
												</router-link>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
												<router-link class="underline-none" :to="`/HomeGuides/`" v-slot="{ navigate }">
													<v-avatar  @click="navigate" size="128" tile>
													<img
														src="@/assets/flaticon/tour-guide.png"
														alt="conociendo Copacabana"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Guías de Turismo</span><br>
													<span class="fs-0-9 grey--text">Personal altamente<br>
													capacitado</span>
												</router-link>
												</div>
											</v-col>
										</v-row>
									</v-flex>
								</div>
							</v-col>
							<v-col cols="1"></v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-img>
	</div>
	<div id="div_hospedajes">
		<v-container style="padding-top: 60px; padding-bottom: 40px;">
			<v-flex>
				<v-row>
					<v-col cols="9" md="9">
						<div class="text-left">
							<span class="subtitle-box">ESTADÍA</span><br>
							<span class="fs-2">Establecimientos de Hospedaje</span>
							<hr class="separador-hr">
							<span class="fs-1">Disfruta de tu estadía en el santuario, hospedandote en nuestros hoteles con gran variedad de servicios y costo variado</span><br>
						</div>
					</v-col>
				</v-row>
				<v-carousel height="420" hide-delimiters style="box-shadow: 0px 0px">
					<v-carousel-item v-for="i in 2" :key="i">
						<v-layout row>
							<v-flex sm6 md3 lg2 v-for="ho in list_hospedaje" :key="ho.id" pl-2 pr-2>
								<router-link class="underline-none" :to="'/SiteHospedaje/'+ho.id" v-slot="{ navigate }">
									<v-card class="mx-auto my-12"
										max-width="374"
										color="transparent"
										@click="navigate"
									>
										<template slot="progress">
										<v-progress-linear
											color="deep-purple"
											height="10"
											indeterminate
										></v-progress-linear>
										</template>
										<v-img
										height="250"
										class="white--text align-start text-right"
										:src="$Api_url_media+ho.img_principal"
										>
											<v-chip class="ma-2" color="blue" label small text-color="white">
												{{ho.tipo}}
											</v-chip>
										</v-img>
										<v-card-text>
											<div>
												<span class="text-subtitle-1 font-weight-bold">{{ho.tipo}} {{ho.nombre}}</span>	
											</div>
											<div class="row">
												<div class="col-12">
													<div class="grey--text font-weight-light text-truncate">
														{{ho.direccion}}
													</div>
												</div>	
											</div>
											<div class="row">
												<div class="col-5" style="padding-top:0; padding-bottom:0;">
													<v-rating
													:value="ho.categoria"
													color="amber"
													dense
													half-increments
													readonly
													size="14"
													></v-rating>
												</div>
												<div class="col-7" style="padding-top:0; padding-bottom:0;">
													<div class="text-right" v-if="ho.precio_min > 0">
														<span class="font-weight-bold">Desde ${{ho.precio_min}} </span>noche
													</div>
												</div>
											</div>
										</v-card-text>
									</v-card>
								</router-link>
							</v-flex>
						</v-layout>
					</v-carousel-item>
				</v-carousel>
				<v-row>
					<v-col cols="12">
						<div class="text-center pa-6">
							<router-link class="underline-none" to="/SiteHospedajes" v-slot="{ navigate }">
								<v-btn @click="navigate" rounded large color="blue" outlined><v-icon left>mdi-arrow-right</v-icon>ver todo</v-btn>
							</router-link>
						</div>
					</v-col>
				</v-row>
			</v-flex>	
		</v-container>
	</div>
	<div id="informacion_util" class="banner-rotated">
		<v-img height="600" aspect-ratio="1.7" src="@/assets/img/a66.jpg">
			<v-row>
				<v-col cols="12" class="text-center">
					<h2 class="titulo-portada custom-title container-fluid white--text">Planifica tu viaje</h2>
					<span class="text-h6 white--text">Descubre más información de :</span>
				</v-col>
				<v-col cols="12">
					<v-container>
						<v-row>
							<v-col cols="1"></v-col>
							<v-col cols="10">
								<div style="background: rgba(247,247,247,0.9);" class="pa-4">
									<v-flex class="my-5">
										<v-row dense>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
												<router-link class="underline-none" :to="'/HomeComoLlegar/'" v-slot="{ navigate }">
													<v-avatar @click="navigate" size="128" tile>
													<img
														src="@/assets/flaticon/escuela-de-autobuses.png"
														alt="como llegar"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Cómo llegar a <br>
													Copacabana</span><br>
													<span class="fs-0-9 grey--text">Diferentes formas de llegar</span>
												</router-link>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
												<router-link class="underline-none" :to="'/HomeConociendoCopacabana/'" v-slot="{ navigate }">
													<v-avatar  @click="navigate" size="128" tile>
													<img
														src="@/assets/flaticon/lancha-rapida.png"
														alt="conociendo Copacabana"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Conociendo Copacabana  <br> en un Día</span><br>
													<span class="fs-0-9 grey--text">Tus mejores vacaciones</span>
												</router-link>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="text-center pointer" @click="openMapa">
													<v-avatar size="128" tile>
													<img
														src="@/assets/flaticon/mapa.png"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Mapa</span><br>
													<span class="fs-0-9 grey--text">Localiza lo que te interesa</span>
												</div>
											</v-col>
											<v-col cols="6" md="3">
												<div class="text-center pointer">
													<v-avatar size="128" @click="dialog_resena=true" tile>
													<img
														src="@/assets/flaticon/buena-resena.png"
													>
													</v-avatar><br>
													<span class="fs-1 mt-3">Mi Viaje</span><br>
													<span class="fs-0-9 grey--text">Escribe reseñas de tu visita</span>
												</div>
											</v-col>
										</v-row>
									</v-flex>
								</div>
							</v-col>
							<v-col cols="1"></v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-img>
	</div>
	<div id="div_noticias">
		<v-container style="padding-top: 60px;">
			<v-flex class="my-10">
				<v-row>
					<v-col cols="9" md="9">
						<div class="text-left">
							<span class="subtitle-box">MANTENTE INFORMADO</span><br>
							<span class="fs-2">Noticias Relevantes del Municipio</span>
							<hr class="separador-hr">
							<span class="fs-1">Aquí encontraras información relevante acerca de las actividades mancomunadas que lleva adelante el Gobierno Autonómo Municipal de Copacabana juntamente a la Dirección de Turismo Copacabana y la población en general</span>
						</div>
					</v-col>
					<v-col cols="4" md="4">
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6" md="3" v-for="not in list_noticias" :key="not.id">
						<v-card
							class="mx-auto"
							max-width="344"
						>
							<v-img
							:src="$Api_url_media+not.img"
							height="200px"
							>
							</v-img>
							<v-card-text>
								<div>
									<v-chip color="green" label x-small text-color="white">
										{{not.categoria}}
									</v-chip><br>
									<span class="text-subtitle-1 font-weight-bold">{{not.titulo}}</span>	
								</div>
								<div class="row">
									<div class="col-12">
										<div class="grey--text font-weight-light text-truncate">
											{{not.entrada}}
										</div>
										<p class="text-caption">Fecha: {{not.fecha_publicacion | fecha_literal}}</p>
									</div>	
								</div>
							</v-card-text>
							<v-card-actions>
								<router-link class="underline-none" :to="'/SiteNoticia/'+not.id" v-slot="{ navigate }">
									<v-btn @click="navigate" color="orange lighten-2" text><v-icon left>mdi-arrow-right</v-icon>Ver nota</v-btn>
								</router-link>
							<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<div class="text-center">
							<router-link class="underline-none" to="/SiteNoticias" v-slot="{ navigate }">
								<v-btn @click="navigate" rounded large color="blue" outlined><v-icon left>mdi-arrow-right</v-icon>ver todo</v-btn>
							</router-link>
						</div>
					</v-col>
				</v-row>
			</v-flex>
		</v-container>
	</div>
	<div id="resenas" style="background: #F7F7F7;">	
		<v-container style="padding-top: 60px;">
			<v-flex class="my-10">
				<v-row>
					<v-col cols="12"  md="6">
						<div>
							<span class="fs-2">Últimas Reseñas</span>
							<hr class="separador-hr">
							<span class="fs-1">Comparte tu experiencia y ayuda a cientos de personas que pretenden visitar la belleza de Copacabana</span>
						</div>
						<div class="pa-10 wow fadeInLeft">
							<v-img src="@/assets/resenas1.png"></v-img>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div class="mb-4" v-for="resena in list_resenas" :key="resena.id">
							<v-card class="wow fadeInUp" data-wow-delay="400ms">
								<v-card-text>
									<v-list four-line>
										<template class="d-flex">
											<v-list-item>
												<v-list-item-avatar class="align-self-start" size="60" v-if="resena.img_user">
													<v-img :src="$Api_url_media+resena.img_user"></v-img>
												</v-list-item-avatar>

												<v-list-item-avatar class="align-self-start" size="60" v-else>
													<v-img src="@/assets/user2.png"></v-img>
												</v-list-item-avatar>

												<v-list-item-content>
													<v-list-item-title>{{resena.autor}}</v-list-item-title>
													<v-list-item-subtitle>{{resena.fecha_publicacion | fecha_literal}}</v-list-item-subtitle>
													<v-rating
													v-model="resena.rating"
													background-color="orange lighten-3"
													color="orange"
													small
													dense
													readonly
													></v-rating>
													<span class="text-subtitle-2 ps-0 py-1">{{resena.titulo}}</span><br>
													<span class="fs-0-9" style="line-height:1.3rem;">{{resena.contenido}}</span>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-list>
								</v-card-text>
							</v-card>	
						</div>
						<v-row class="mt-7">
							<v-col cols="12">
								<div class="text-center">
									<router-link class="underline-none" to="/HomeResenas" v-slot="{ navigate }">
										<v-btn @click="navigate" rounded large color="primary" outlined><v-icon left>mdi-arrow-right</v-icon>ver todo</v-btn>
									</router-link>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-flex>
		</v-container>
	</div>
	<div id="div_nosotros">
		<v-container style="padding-top: 60px;">
			<v-flex class="mx-15">
				<v-row>
					<v-col cols="12" md="6">
						<v-row>
							<v-col cols="3" md="3" class="pt-15 d-none d-sm-flex">
								<v-img class="mx-auto" src="@/assets/img/escudo.png" width="250">
								</v-img>
							</v-col>
							<v-col cols="12" md="9">
								<v-container class="mt-5">
									<span class="fs-2">
										Dirección de Cultura y Turismo <br>
										Copacabana
									</span><br><br>
									<p>
										<v-icon color="blue">mdi-map-marker</v-icon>&nbsp;
										Plaza Sucre, Copacabana Bolivia
									</p>
									<p>
										<v-icon color="blue">mdi-email</v-icon>&nbsp;
										copacabanasomostudestino@gmail.com
									</p>
									<p>
										<v-icon color="blue">mdi-phone</v-icon>&nbsp;
										72516462
									</p>
								</v-container>
								<v-row>
									<v-col cols="12">
										<div class="fs-1-5 justify-center">
										Encuentranos también en:
										</div>
										<div class="text-left">
											<v-list-item-avatar color="indigo">
												<a target="_blank" href="https://www.facebook.com/Copacabanagam"><v-icon dark>mdi-facebook</v-icon></a>
											</v-list-item-avatar>
											<v-list-item-avatar
											 color="black"
											 class="clickable"
											 >
											 <a target="_blank"
											 href="https://www.tiktok.com/@copacabanasomostudestino">
												<div style="width:25px; height:25px;">
													<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tiktok" width="20" height="20" viewBox="0 0 24 24" stroke-width="0.8" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
													<path d="M21 7.917v4.034a9.948 9.948 0 0 1 -5 -1.951v4.5a6.5 6.5 0 1 1 -8 -6.326v4.326a2.5 2.5 0 1 0 4 2v-11.5h4.083a6.005 6.005 0 0 0 4.917 4.917z" />
													</svg>
												</div>
											 </a>
											</v-list-item-avatar>
											<v-list-item-avatar color="green">
												<a target="_blank" href="https://w.app/Mjg2eJ">
													<v-icon dark>mdi-whatsapp</v-icon>
												</a>
											</v-list-item-avatar>
										</div>
									</v-col>
								</v-row>
							</v-col>
						</v-row>				
					</v-col>
					<v-col cols="12" md="6">
						<iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7028.137552529651!2d-69.08804086311453!3d-16.16281986022165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2sbo!4v1673794327002!5m2!1ses!2sbo" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</v-col>
				</v-row>
			</v-flex>
		</v-container>
	</div>
  </div>
</template>
<style scoped>
	@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');

	.navbar{
		z-index: 9999;
	}

	.carousel-3d-slide {
		background-color: transparent;
	}
	.banner-rotated .custom-title {
		font-size: 6.5vw;
		font-weight: 500;
		font-family: 'Mega-Fresh';
    	transform: rotate(-3deg);
	}
	.card-simplex{
		background-color: transparent !important;
    	border-color: #fff !important;
	}
	figure.effect-sadie h2 {
    	color: #ffffff;
		text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
	}
	.curved-div .v-card {
		transition: opacity .4s ease-in-out;
	}

	.curved-div .v-card:not(.on-hover) {
		opacity: 0.7;
	}

	.show-btns {
		color: rgba(255, 255, 255, 1) !important;
	}

	.map{
		width:100%;
		height:500px;
	}

	/*hero*/
	.hero{
		width:100%;
		height:100vh;
		background-image:linear-gradient(rgba(12,3,51,0.3), rgba(12,3,51,0.3));
		position:relative;
		padding:0 5%;
		display:flex;
		align-items: center;
		justify-content: center;
	}

	nav{
		width:100%;
		position:absolute;
		top:0;
		left:0;
		padding:20px 8%;
		display:flex;
		align-items:center;
		justify-content:space-between;
		background:none !important;
	}

	nav .logo{
		width:100px;
	}
	nav ul li{
		list-style:none;
		display:inline-block;
		margin-left:40px;
	}
	nav ul li a{
		text-decoration: none;
		text-transform: uppercase;
		color:#fff;
		font-size:17px;
		font-weight:600;
		padding:0 !important;
		display:inherit;
		line-height:1;
	}
	nav ul li a:hover{
		background-color: #fff;
		color: black;
		border-radius:3px;
		padding:5px 10px !important;
		transition: all .5s ease;
	}

	.z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
		box-shadow: none;
	}

	.content{
		text-align: center;	
	}
	.content h1{
		font-size:20vw;
		color:#fff;
		font-weight:500;
		position:relative;
		font-family: 'Mega-Fresh', cursive;
		text-shadow: 3px 3px 1px rgba(0,0,0,0.5);
	}
	.content h3{
		color:#fff;
		position:relative;
		font-weight:500;
		font-size: 1.5em;
	}
	.content a{
		text-decoration: none;
		display: inline-block;
		color:#fff;
		font-size:24px;
		border:2px solid #fff;
		padding:14px 70px;
		border-radius:50px;
		margin-top:20px;
		position:relative;
	}

	.content a:hover{
		background-color: #fff;
		color:black;
	}

	.back-video{
		position:absolute;
		right:0;
		bottom:0;
	}

	.clickable{
		cursor:pointer;
	}

	@media (min-aspect-ratio: 16/9){
		.back-video{
			width:100%;
			height:auto;
		}
	}

	@media (max-aspect-ratio: 16/9){
		.back-video{
			width:auto;
			height:100%;
		}
	}

	

	/*animaciones*/
	.img-sepia:hover {
		filter: sepia(60%);
		transition:all .5s ease-in-out;
		}

	.img-opacity:hover
	{
		filter: opacity(.5);
		transition:all .3s ease-in-out;
	}

	.titulo{
		font-size: 4rem;
		text-align: center;
		font-family: 'Mega-Fresh', cursive;
		text-shadow: 2px 2px 1px rgba(0,0,0,0.7);
	}
	.subtitulo{
		font-size:1.3rem;
		text-align: center;
		text-shadow: 2px 2px 1px rgba(0,0,0,0.7);
	}

/*estilos de materialize*/

.carousel .carousel-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 350px;
    height: 350px;
}

.carousel{
    min-height: 350px;
}

.carousel .carousel-item img{
    width:100%;
    height:100%;
    object-fit: cover;
    position:absolute;
    top:0;
    left:0;
    z-index:-1;
    border-radius:15px;
}

.carousel .indicators .indicator-item{
    border-radius: 0;
    transform:rotate(45deg);
    margin:0 15px;
    background: #36221c;
}


/*Carousel 2*/
h4, h2,small,a{
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
}
.Carousel{
    width: 100%;
    margin: 15px auto;
}

/* images */
.slick-track{
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transition: .5s ease-in-out;
}
.slick{
    position: relative;
    width: 100%;
    padding: 0 10px;
    float: left;
    box-sizing: border-box;
    display: flex;
    height: 100%;
	overflow:hidden;
}
.slick h4{
    position: absolute;
    z-index: 1;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    padding: 15px;
	text-shadow: 2px 2px 1px rgba(0,0,0,0.7);
}
.slick h4 small{
    font-size: 1rem;
    display: block;
	text-shadow: 2px 2px 1px rgba(0,0,0,0.7);
}
.slick a img{
    object-fit: cover;
    height: 400px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.5);
    transition: .3s ease-in-out;
}

.slick:hover img {
	-webkit-transform:scale(1.1);
	transform:scale(1.1);
	}

/* buttons */

.slick-arrow{
    border-radius: 30px;
    background-color: #fff;
    position: absolute;
    z-index: 4;
    width: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
    border: 0;
    cursor: pointer;
}
.cursor{
	cursor:pointer;
}
.slick-arrow:focus{
    outline: 0;
}
.slick-arrow svg{
    width: 12px;
    height: 100%;
    color: rgba(0,0,0,.7);
}
.slick-prev{
    left: 0px;
}
.slick-next{
    right: 0px;
}
#div_hospedajes .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
#div_hospedajes .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar){
    border-radius: 20px;
}
/*Reseñas*/

.bg
{
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../assets/turismo.jpg);
  background-size: cover;
}

.resena {
  position: relative;
  z-index: 1; 
  }
  .resena .img-thumb {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55px;
    flex: 0 0 55px;
    max-width: 55px;
    width: 55px;
    height: 55px;
    border: 2px solid transparent;
    margin-right: 40px;
    margin-top: 15px;
    border-radius: 50%; }
    .resena .img-thumb img {
      border-radius: 50%; }
  .resena .resena-contenido h5 {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 30px; }
  .resena .resena-contenido p {
    color: #ffffff; }
  .resena .resena-contenido h6 {
    color: #ffffff;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px; }
    .resena .resena-contenido h6 span {
      color: #0099ff; }
  .resena:hover .img-thumb {
    border-color: #0099ff; }
  .resena:hover .resena-contenido h5 {
    color: #0099ff; }
/*///////Reseñas*/


#informacion_util img:hover {
	filter: opacity(.5);
	transition:all .3s ease;
}

#servicios img:hover {
	filter: opacity(.5);
	transition:all .3s ease;
}

.color-white{
	color:#fff;
}


</style>
<script>
// @ is an alias to /src
import WOW from '@/plugins/wow.min.js';
import SiteServices from '@/services/SiteServices';
import ResenaService from '@/services/ResenasService';
import { Carousel3d, Slide } from 'vue-carousel-3d';
// var wow = new WOW({ scrollContainer: "#scrolling-body"});
export default {
  name: 'Home',
  data(){
    return{
		dialog_advertencia:false,
		image_user:'',
		valid: true,
		camposRules: [
			v => !!v || 'El campo es requerido'
		],
		ratingRules:[
			v => v > 0 || 'Calificación requerida'
		],
		contenidoRules: [
			v => !!v || 'El campo es requerido',
			v => v.length <= 600 || 'Max. 600 caracteres'
		],
		list_resenas:[],
		dialog_resena :false,
		resena:{
			autor:'',
			titulo:'',
			contenido:'',
			fecha_publicacion:new Date().toISOString(),
			rating:5, 
			estado:0, 
			img_user:''
		},
		tab_atractivos:null,
      	bg:'transparent',
      	altura_ini_p:500,
	  	drawer:false,
      	icons: [
			'mdi-facebook',
			'mdi-twitter',
			'mdi-linkedin',
			'mdi-instagram',
        ],
      	items: [
			{
				src: 'https://www.incaworldbolivia.com/fotos/0915201694507-Isla-del-sol-Bolivia.jpg',
			},
			{
				src: '../assets/copaini.png',
			},
		],
		list_atractivos:[],
		list_eventos:[],
		list_actividades:[],
		list_noticias:[],
		list_hospedaje:[]
    }
  },
  mounted(){	
		// this.altura_ini_p=(window.innerHeight)-64;
		this.altura_ini_p=(window.innerHeight);
    	window.onscroll = () => {
			this.changeColor();
		};
		setTimeout(() => (this.activa_inicio()), 1000);
		this.scroll_ini();
		this.get_atractivos();
		this.get_eventos();
		this.get_hospedajes();
		this.get_noticias();
		this.get_resenas();	
		this.get_actividades();

		this.doOnce();
  },
  filters:{
	fecha_literal:function(value){
		let fecha_literal ='';
		if (value){
		let fecha = new Date(value);
		let dia = ('0'+(fecha.getDate())).slice(-2);
    	let anio = fecha.getFullYear();
		let mes = fecha.getMonth()+1;
		let mesLiteral = '';
		switch(mes){
			case 1:
				mesLiteral = 'Enero';
				break;
			case 2:
				mesLiteral = 'Febrero';
				break;
			case 3:
				mesLiteral = 'Marzo';
				break;
			case 4:
				mesLiteral = 'Abril';
				break;
			case 5:
				mesLiteral = 'Mayo';
				break;
			case 6:
				mesLiteral = 'Junio';
				break;
			case 7:
				mesLiteral = 'Julio';
				break;
			case 8:
				mesLiteral = 'Agosto';
				break;
			case 9:
				mesLiteral = 'Septiembre';
				break;
			case 10:
				mesLiteral = 'Octubre';
				break;
			case 11:
				mesLiteral = 'Noviembre';
				break;
			case 12:
				mesLiteral = 'Diciembre';
				break;
			} 
			fecha_literal = `${dia} de ${mesLiteral} de ${anio}`;
		}
		return fecha_literal;
	},
	fecha_literal_mes:function(value){
		let fecha_literal ='';
		if (value){
		let fecha = new Date(value);
		let dia = ('0'+(fecha.getDate()+1)).slice(-2);
		let mes = fecha.getMonth()+1;
		let mesLiteral = '';
		switch(mes){
			case 1:
				mesLiteral = 'Enero';
				break;
			case 2:
				mesLiteral = 'Febrero';
				break;
			case 3:
				mesLiteral = 'Marzo';
				break;
			case 4:
				mesLiteral = 'Abril';
				break;
			case 5:
				mesLiteral = 'Mayo';
				break;
			case 6:
				mesLiteral = 'Junio';
				break;
			case 7:
				mesLiteral = 'Julio';
				break;
			case 8:
				mesLiteral = 'Agosto';
				break;
			case 9:
				mesLiteral = 'Septiembre';
				break;
			case 10:
				mesLiteral = 'Octubre';
				break;
			case 11:
				mesLiteral = 'Noviembre';
				break;
			case 12:
				mesLiteral = 'Diciembre';
				break;
			} 
			fecha_literal = `${dia} de ${mesLiteral}`;
		}
		return fecha_literal;
	},
  },
  methods:{
	openMapa(){
		window.open('http://drive.google.com/uc?export=view&id=1AFgAbsNQruV1mIHf78uRsJq034WocdnW', '_blank');
	},
	doOnce() {
	if (!document.cookie.split("; ").find((row) => row.startsWith("gamcopacabana"))
		) { 
			let expires = "";
			let date = new Date();
            date.setTime(date.getTime() + (40*60*1000)); //40 minutos de para la expiracion de la cookie
            expires = "expires=" + date.toUTCString();
			document.cookie = `gamcopacabana=true; ${expires}; SameSite=None; Secure`;

			this.dialog_advertencia = true;
		}
	},
	 notification(title, icon){
          this.$swal.fire({
          position: 'top-end',
          icon,
          title,
          showConfirmButton: false,
          timer: 1500
        })
     },
	openLink(module, id){
		this.$router.replace('/'+module+'/'+id);
	},
	limpiarResena(){
		this.resena = {
			autor:'',
			titulo:'',
			contenido:'',
			fecha_publicacion:new Date().toISOString(),
			rating:5, 
			estado:0, 
			img_user:''
		};
		this.image_user='';
	},
	toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

	updateFile(event) {
      if(event!=null){
        // generamos un nuevo nombre de imagen
        var fileName = event.name;
        var extFile = fileName.split('.').pop();
        this.resena.img_user=(Math.random().toString(16).slice(2)) +'.'+ extFile;
        // para la visualizacion convertimos la imagen
        const file = event; 
        this.toBase64(file).then(base64 => {
          this.image_user=base64;
        });
      }else{
        this.image_user='';
      }
    },
	validateResena(){
		if(this.$refs.form.validate()){
			this.guardaResena();
		}
		else{
			this.notification("Debe llenar los campos", "warning");
		}
	},
	saludo(){
		this.notification("Saludo", "success");
	},
	 FormDataImage(id_element, nombre_archivo){
      const fileinput= document.getElementById(id_element);
      if(fileinput.files.length > 0){
        const formData = new FormData();
        formData.append('file', fileinput.files[0], nombre_archivo);
        return formData;
      }
	  else{
        return null;
      }
    },
	guardaResena(){
		let dataimagen=this.FormDataImage('file_imagen_principal', this.resena.img_user);

		this.resena.img_user = `/api/containers/multimedia/download/${this.resena.img_user}`

		ResenaService.saveResena(this.resena)
		.then(response=>{
			let id_resena = response.data.id;
			if(dataimagen) {
				this.guardaImagenUser(id_resena, dataimagen);
				}
			this.$swal.fire(
			'Buen trabajo!',
			'Tu reseña ha sido registrada y esta a la espera de su aprobación, agradecemos tu gentil colaboración',
			'success'
			);
			this.limpiarResena();
			this.dialog_resena = false;
		})
	},
	guardaImagenUser(id_resena, dataimagen){
      ResenaService.saveImage(id_resena, dataimagen).then(response=>{
        console.log(response.data);
        this.image_user='';
      })
    },
	scroll_ini(){
		document.querySelector('#scrolling-body').scrollTo(0,0);
	},
	get_actividades(){
		SiteServices.getActividad(5).then(response=>{
			this.list_actividades=response.data;
		})
	},
	get_eventos(){
		SiteServices.getEventosLimit(8).then(response=>{
			this.list_eventos = response.data;
		})
	},
	get_atractivos(){
		SiteServices.getAtractivoLimit(8).then(response=>{
			this.list_atractivos=response.data;
		})
	},
	get_hospedajes(){
		SiteServices.getDataLimit('hospedajes', 15).then(response=>{
			this.list_hospedaje=response.data;
		})
	},
	get_noticias(){
		SiteServices.getNoticiasLimitAndOrder(4).then(response=>{
			this.list_noticias=response.data;
		})
	},
	get_resenas(){
		SiteServices.getResenaLimit(4).then(response=>{
			this.list_resenas=response.data;
		})
	},
	ordenaFecha(fecha){
      let dia = ('0'+fecha.getDate()).slice(-2);
      let mes = ('0'+(fecha.getMonth()+1)).slice(-2);
      let anio = fecha.getFullYear();
      return `${dia}-${mes}-${anio}`;
     },
    activa_inicio(){
      var wow = new WOW({ scrollContainer: "#scrolling-body"});
      wow.init();
    },
    changeColor() {
      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
          this.bg = 'white';
      } else {
          this.bg = 'transparent';
      }
    },
  },
  components: {
	'carousel-3d': Carousel3d,
    'slide':Slide
  }
}
</script>
