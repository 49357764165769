<template>
	<div style="background: #F7F7F7;" id="div_atractivo">
		<!--<v-img :height="altura_ini_p" class="white--text align-end" :src="$Api_url_media+data_atractivo.img_principal">-->
        <v-img :height="altura_ini_p" class="white--text align-end" src="@/assets/img/a200.jpg">
			<v-container style="padding: 10px 15px 30px 15px;">
				<v-row>
					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="10">
						<span class="font-weight-black" style="font-size: 3rem; text-shadow: 0 2px 4px rgb(0 0 0 / 50%);">Conociendo Copacabana en un Día</span><br>
						<span class="text-h6" style="text-shadow: 0 2px 4px rgb(0 0 0 / 50%);">¿Qué actividades se puede hacer en Copacabana en un día?.</span>
					</v-col>
					<v-col cols="12" md="1"></v-col>
				</v-row>
			</v-container>
		</v-img>
		<v-container>
			<v-flex>
				<v-row>
					<v-col cols="12"></v-col>
				</v-row>
				<v-row>

					<v-col cols="12" md="1"></v-col>
					<v-col cols="12" md="10">
						<div>
                            <p>
                                Si en tu itinerario no dispones de mucho tiempo, pero deseas hacer algunas actividades en Copacabana  y luego seguir viaje, puedes tomar en cuenta la siguiente alternativa:
                            </p>
                            <p class="font-weight-bold">City tour población de Copacabana</p>
                            <p>
                               Entre las actividades más comunes para hacer en Copacabana, se encuentran las siguientes:
                            </p>
                            <p><span class="font-weight-bold">Plaza Principal</span>
                                con su hermosa basílica de estilo barroco mestizo, visitada a diario por los feligreses en devoción a la Virgen de Copacabana, y con una famosa peregrinación anual.
                            </p>
                            <v-img
                              class="mx-auto my-3"
                              max-width="600"
                              src="@/assets/img/10252016104040-basílica-santuario-copacabana.jpg"
                            ></v-img>
                            <p><span class="font-weight-bold">Observatorio Astronómico</span>
                                que se encuentra al sur de la población, a unos 20 minutos de la plaza principal. Allí está emplazado el observatorio astronómico de Copacabana.
                            </p>
                            <v-img
                              class="mx-auto my-3"
                              max-width="600"
                              src="@/assets/img/observatorio.jpg"
                            ></v-img>
                            <p><span class="font-weight-bold">El Calvario</span>
                                es un reto porque deberás trepar unos cuantos metros. Vale la pena por las vistas increíbles que tendrás desde allí. A lo largo de este cerro, se encuentran las 14 estaciones del Vía Crucis. Te recomendamos llegar para el atardecer, ya que la vista del lago en esa hora de la tarde es inigualable.
                            </p>
                            <v-img
                              class="mx-auto my-3"
                              max-width="600"
                              src="@/assets/img/calvario.jpg"
                            ></v-img>
                            
                            <p><span class="font-weight-bold">La Playa</span>
                                es uno de los lugares más concurridos por quienes gustan de los juegos acuáticos. Podrás elegir entre veleros a motor, lanchas a pedales, motos acuáticas y otras actividades, o simplemente disfrutar del paisaje en una terraza o comer pescado fresco en uno de los numerosísimos puestos.
                            </p>
                             <v-img
                              class="mx-auto my-3"
                              max-width="600"
                              src="@/assets/img/a100.jpg"
                            ></v-img>
                            <p><span class="font-weight-bold">Copacabana – Isla del Sol (parte Sur)</span>
                                Una vez en Copacabana, puedes dirigirte al muelle para tomar el bote rumbo a la Isla del Sol (parte Sur). El viaje dura alrededor de una hora y media, y el último bote sale a la 13:30 pm.
                            </p>
                            <p>
                                Tu estadía en la Isla del Sol (parte Sur) será de 3 horas donde puedes observar:
                            </p>
                            <ul>
                              <li>
                              Escalinata del Inca (Yumani)
                              </li>
                              <li>
                              Palacio Pilcocaina
                              </li>
                              <li>
                              Muelle de Yumani
                              </li>
                              <li>
                              Fuente de la Vida
                              </li>
                            </ul>
                            <v-img
                              class="mx-auto my-3"
                              max-width="600"
                              src="@/assets/img/turismo.jpg"
                            ></v-img>
                            <p>
                            Tras culminar el recorrido en la parte Sur de la Isla del Sol, debes tomar una lancha a las  16:30 pm rumbo a Copacabana, llegando a  la población a las 18pm.
                            </p>
                            <p>
                            Entre las mejores opciones que encontrarás para recorrer Copacabana y la Isla del Sol, están los tours que ofrece Tickets Bolivia y que van desde recorridos breves de una tarde a tours completos de varios días. Ingresando a este link 
                            <a href="https://www.ticketsbolivia.com.bo/tours-bolivia">https://www.ticketsbolivia.com.bo/tours-bolivia</a>
                            , podrás elegir el que se adapte mejor a tus gustos, presupuesto y tiempo de viaje.
                            </p>
                            <p>
                            Si en tu itinerario de viaje está en proseguir viajando y no quedarte en Copacabana lo puedes hacer de la siguiente manera:
                            </p>
                            <p>
                            Si quieres ir desde <span class="font-weight-bold red--text">Copacabana hasta Puno</span>, en el vecino Perú, recuerda que deberás cruzar la frontera, por lo que debes tener la documentación necesaria. El viaje de Copacabana a Puno es bus dura tres horas en los sigueintes horarios y empresas de transporte:
                            </p>
                            <p>
                            <ul>
                            <li>
                            <span class="font-weight-bold">Trans Titicaca: </span> sale a las 9:00 am, 13:30 pm y 18:30 pm en bus normal
                            </li>
                            <li>
                              <span class="font-weight-bold">Tour Perú: </span> sale a las 13:30 en un bus normal.
                            </li>
                            <li>
                              <span class="font-weight-bold">Huayruro Tours: </span> sale a las 18:00 pm en un bus cama o semicama.
                            </li>
                            <li>
                              <span class="font-weight-bold">Transzela: </span> sale a las 18:00 pm en un bus cama o semicama.
                            </li>
                            </ul>
                            </p>
                            <v-img
                              class="mx-auto my-3"
                              max-width="600"
                              src="@/assets/img/bus_copa.jpeg"
                            ></v-img>
                            <p>
                            Si quieres viajar de <span class="font-weight-bold red--text">Copacabana a Cusco</span>, en un recorrido de 10 horas aproximadamente, puedes hacerlo en los siguientes horarios de diferentes empresas:
                            </p>
                            <ul>
                            <li>
                            <span class="font-weight-bold">Transzela: </span> sale a las 17:30pm en un bus cama
                            </li>
                            <li>
                              <span class="font-weight-bold">Huayruro Tours: </span> sale a las 18:00pm en bus cama o semicama.
                            </li>
                            <li>
                              <span class="font-weight-bold">Trans Titicaca: </span> sale a las 18:30pm en un bus semicama.
                            </li>
                            </ul>

                            <p class="font-weight-bold mt-1">Tips</p>
                            <ul>
                            <li>
                            Te recomendamos que estés en la terminal de buses de La Paz 30 minutos antes de tu salida a Copacabana
                            </li>
                            <li>Recuerda que en la terminal deberás pagar Bs 2,50 por la tarifa de uso de instalaciones, la cual te pedirán al abordar el bus.</li>
                            <li>Si deseas conocer las distintas empresas de buses que ofrecen el servicio desde la ciudad de La Paz a Copacabana, y desde Copacabana hacia el Perú, ingresa a <a href="http://www.ticketsbolivia.com">http://www.ticketsbolivia.com</a></li>
                            <li>Te recomendamos que, cuando bajes del bus en el estrecho de Tiquina para tomar el bote, tengas a mano tus documentos personales (pasaporte, hoja de migración) porque a veces se hace un control rutinario.</li>
                            <li>Tienes que tomar en cuenta que en el estrecho de Tiquina no puedes distraerte en tomar fotografías o ir a comer porque, una vez que se realice el cruce del lago Titicaca, inmediatamente tienes que subir al bus para proseguir con la ruta.</li>
                            </ul>

                        </div>
					</v-col>
					<v-col cols="12" md="1"></v-col>
				</v-row>
			</v-flex>
		</v-container>
	</div>
</template>
<script>
// @ is an alias to /src
//import WOW from '@/plugins/wow.min.js';
//import MultimediaService from '@/services/MultimediaService';
// var wow = new WOW({ scrollContainer: "#scrolling-body"});
export default {
  name: 'ConociendoCopacabana',
  data(){
    return{
      bg:'transparent',
      altura_ini_p:500,
	  drawer:false,
	  imagenes: [
        {
          largeURL:
            'https://live.staticflickr.com/6099/6284904269_ea2148a6c9_b.jpg',
          thumbnailURL:
            'https://live.staticflickr.com/6099/6284904269_ea2148a6c9_b.jpg',
          width: 1500,
          height: 1500,
        },
        {
          largeURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg',
          thumbnailURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg',
          width: 1669,
          height: 2500,
        },
        {
          largeURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
          thumbnailURL:
            'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
          width: 2500,
          height: 1666,
        },
      ],
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
        ],
      items: [
          {
            src: 'https://www.incaworldbolivia.com/fotos/0915201694507-Isla-del-sol-Bolivia.jpg',
          },
          {
            src: 'https://boliviaturistica.com/wp-content/uploads/2018/08/Copacabana-Bolivia.jpg',
          },
      ],
	  data_atractivo:{},
	  multimedia_data:[]
    }
  },
  mounted(){	
		this.altura_ini_p=(window.innerHeight)-64;
    	window.onscroll = () => {
			this.changeColor();
		};
		setTimeout(() => (this.activa_inicio()), 1000);	
		this.scroll_ini();
		// this.get_atractivo();
		// this.getMultimediaGaleria();
  },
  methods:{

 
	scroll_ini(){
		document.querySelector('#scrolling-body').scrollTo(0,0);
	},
    // activa_inicio(){
    //   var wow = new WOW({ scrollContainer: "#scrolling-body"});
    //   wow.init();
    // },
    // changeColor() {
    //   if (
    //       document.body.scrollTop > 100 ||
    //       document.documentElement.scrollTop > 100
    //   ) {
    //       this.bg = 'white';
    //   } else {
    //       this.bg = 'transparent';
    //   }
    // },
  }
}
</script>
