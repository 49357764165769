var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background":"#F7F7F7"},attrs:{"id":"div_servicios"}},[_c('v-container',[_c('v-flex',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"mt-3 justify-center align-center"},[_c('span',{staticClass:"title-box px-4",staticStyle:{"color":"#021F3C"}},[_vm._v(" "+_vm._s(_vm.titulo)+" ")])]),_c('div',{staticClass:"contenido"},[_c('v-row',[_c('p',{staticClass:"my-4"},[_vm._v(_vm._s(_vm.descripcion))]),_c('v-text-field',{attrs:{"placeholder":"Búsqueda por Nombre","outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}})],1),(_vm.filter_list.length>0)?_c('v-row',_vm._l((_vm.filter_list),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"600"}},[_c('router-link',{staticClass:"underline-none",attrs:{"to":'/HomeService/'+item.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('div',{staticClass:"cursor",on:{"click":navigate}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"200px","src":_vm.$Api_url_media+item.img}},[_c('v-card-title',{staticStyle:{"text-shadow":"black 0.1em 0.1em 0.2em"}},[_vm._v(_vm._s(item.nombre))])],1),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(item.referencia)+" ")]),_c('v-card-text',{staticClass:"text--primary"},[_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.ubicacion))]),_c('div',[_c('v-icon',[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(item.direccion)+" ")],1)])],1)]}}],null,true)}),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"mx-2",attrs:{"href":item.facebook,"target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","x-small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-facebook")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Facebook")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"mx-2",attrs:{"href":_vm._f("enlace_whatsapp")(item.whatsapp),"target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green","fab":"","x-small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("WhatsApp")])]),(item.pagina_web)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"mx-2",attrs:{"href":item.pagina_web,"target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"dark","fab":"","x-small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-web")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Página Web")])]):_vm._e()],1)],1)],1)}),1):_c('v-row',{staticClass:"justify-center align-center mt-4"},[_c('p',{staticClass:"text-subtitle-1 grey--text"},[_c('v-icon',[_vm._v("mdi-emoticon-sad-outline")]),_vm._v(" No se encontraron registros")],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }