import axios from 'axios'

export default()=>{
    return axios.create({
        baseURL:'https://webcopa2024-production.up.railway.app/api',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        }
    })
}